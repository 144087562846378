

.cardItem
  display: flex
  align-items: center
  margin: 12px 13px

  p
    margin: 0px

  div[class="icon"]
    padding: 0px 10px 1px 0px

  div[class="tail"]
    margin-left: auto


.cardheader
  padding: 4px 2px 0px 20px
  background-color: #F5F5F5
  border-radius: 4px 4px 0px 0px
  display: flex
  flex-direction: row
  justify-content: space-evenly
  align-items: center
  min-height: 50px

  h4
    color: #525252
    flex: 3
    margin: 0px 0px 2px 0px
    margin-block-start: 1.20
    margin-block-end: 1.20
    font-size: 16px
    font-weight: 600
    white-space: nowrap
    overflow-x: hidden
    text-overflow: ellipsis

  div
    flex: 1
    margin: 0px 7px

  div[class="right"]
    display: flex
    justify-content: flex-end

  button
    margin: -3px -6px -1px 0px
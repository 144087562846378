

.bmisubmissionCard {
  width: 100%;
  position: relative;
  height: 75px;
  display: flex;
  flex-direction: row;

  div[class~="flex-column"] {
    width: 100%;
    height: 100%;
    padding: 8px 8px 6px 85px;

    h4 {
      margin: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 5px;
    }
  }
}

.bmisubmissionIcon {
  position: absolute;
  left: 0;
  height: 100%;
  width: 75px;
  border-right: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bmisubmissionIcon svg {
  width: 45px;
  height: 40px;
}
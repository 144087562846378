.publishDialog {
  width: max-content;
  max-width: 95vw;

  p {
    padding: 0px 17px 5px 17px;
    font-size: 13px;
    color: #676767;
  }

  div[class~="box"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 15px;
    padding: 10px 10px 10px 10px;
    background-color: #f9c03b9b;
    border: 1px solid #f9c03b8e;
    border-radius: 3px;

    code {
      font-weight: 500;
      margin-right: 10px;
      font-family: 'Roboto Mono';
      font-size: 12px;
      overflow: scroll;
      white-space: nowrap;
    }
  }
}

@import "@blueprintjs/core/lib/scss/variables"

.suggestionsField

  input
    box-shadow: none
    border-radius: 4px
    height: 42px

  input:not(:focus)::placeholder
    color: $pt-text-color

  :global
    .bp4-input-group .bp4-input-action
      top: 2px

    .bp4-input-left-container
      svg
        color: $pt-code-text-color

    .error
      color: red
      font-family: $pt-font-family

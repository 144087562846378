.widgetsToAdd {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 15px;
}

.widgetToAdd {
  width: 100px;
  height: 100px;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border: 1px solid #1436BA;
    color: #1436BA;
  }

  span {
    font-size: 12px;
    padding-top: 20px;
  }
}

@import "@blueprintjs/core/lib/scss/variables"

.switchField

  :global
    .error
      color: red
      font-family: $pt-font-family

    label.bp4-label
      display: none

    div.bp4-form-content
      width: 100%
      padding: 0 2px 0 2px
      min-height: unset

      label.bp4-control.bp4-switch
        margin: 2px 0px
        font-size: 14px

        input:checked ~ .bp4-control-indicator
          background: #1436BA

        span.bp4-control-indicator
          margin-top: -0.5px

.disabled
  div
    label
      pointer-events: none
      span
        // TODO: get from vars
        background-color: rgba(143, 153, 168, 0.3) !important

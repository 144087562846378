

.formrow
  display: flex
  flex-direction: row

  &>*
    flex-grow: 1

  &>*:not(:last-child)
    margin-right: 10px

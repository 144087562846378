/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
.formLabel
  margin-left: 2px
  white-space: nowrap
  display: flex !important

  >span
    flex-grow: 1
    font-style: normal
    font-size: 14px

  button
    min-height: 16px !important
    min-width: 16px !important
    padding: 0px 3px 0px 3px !important
    margin-left: 2px
    margin-right: 0px

    span
      font-size: 13px
      color: #1436BA
      min-width: unset !important

      svg
        width: 9px
        height: 9px
        color: #1436BA

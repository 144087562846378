
@import "@blueprintjs/core/lib/scss/variables"

.selectField
  padding: 0px

  button[class~='bp4-button']
    width: 100%
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    display: inline-block
    padding: 10px 10px
    outline: none
    border: 0px solid
    border-radius: 4px
    box-shadow: none
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)
    background-position: calc(100% - 15px) calc(1em + 3px), calc(100% - 10px) calc(1em + 3px), calc(100% - 2.5em) 0.5em
    background-size: 5px 5px, 5px 5px, 1px 1.5em
    background-repeat: no-repeat
    background-color: #fefeff !important
    li
      display: inline-flex
    span[class~="bp4-button-text"]
      flex: 1
    &.placeholder
      *
        color: #5f6b7c !important
    *
      pointer-events: none
    *:hover
      background-color: unset !important
      color: unset !important

  &[class~="bp4-disabled"]
    button
      box-shadow: none !important
      background-color: rgba(211, 216, 222, 0.5) !important
      pointer-events: none
      cursor: not-allowed
      *
        color: rgba(95, 107, 124, 0.6) !important
      img
        filter: grayscale(100%)

.readOnly
  button
    opacity: 1
    color: $pt-text-color
    // background-image: none
.backActivityIndicator {
  width: 100%;

  &.tall {
    margin: 150px 0px;
  }
}

.fadeInAnimation {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.button[class~="bp4-button"] {
  font-family: "Lato";
  font-size: 15px;
  opacity: 1;
  min-height: 33px;
  min-width: 33px;
  border-radius: 4px;
  // padding: 7px 15px;
  transition: all 50ms linear 0ms, opacity 200ms linear 50ms;

  span {
    min-width: 50px;
    text-align: center;
  }

  span:has(svg) {
    min-width: unset;
  }

  // &[class~="bp4-intent-primary"] {
  //   background-color: #1436BA;

  //   &:hover {
  //     background-color: #1436BA;
  //   }
  // }

  &[class~="bp4-intent-warning"] {
    background-color: #f9bf3b;
    color: #5c5c5c;

    &:hover {
      background-color: #ffc53d !important;
      color: #6c5c5c !important;
      // color: white !important;
    }

    &[class~="bp4-disabled"] {
      background-color: #edbe4e !important;
    }
  }

  &.hidden {
    opacity: 0;
    min-width: 0px !important;
    padding: 0px !important;
    margin: 0px !important;
    border: 0px !important;
    font-size: 0px !important;
    transition: all 50ms linear 200ms, opacity 200ms linear 0s;

    span {
      min-width: unset;
    }
  }

  &.loading {
    box-shadow: none;
    position: relative;
    overflow: hidden;
    pointer-events: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 200%;
      height: 100%;
      background: repeating-linear-gradient(300deg,
          transparent,
          transparent 0.5rem,
          rgba(92, 85, 85, 0.4) 0.5rem,
          rgba(92, 85, 85, 0.2) 0.75rem,
        );
      animation: loading 0.8s infinite linear, fadein 1s linear;
    }
  }

  &.loading[class~="bp4-intent-primary"],
  &.loading[class~="bp4-intent-success"],
  &.loading[class~="bp4-intent-danger"] {
    &::before {
      background: repeating-linear-gradient(300deg,
          transparent,
          transparent 0.5rem,
          rgba(255, 255, 255, 0.4) 0.5rem,
          rgba(255, 255, 255, 0.2) 0.75rem,
        );
    }
  }

  &.loading[class~="bp4-intent-warning"] {
    color: #5c5c5c;

    &::before {
      background: repeating-linear-gradient(300deg,
          transparent,
          transparent 0.5rem,
          rgba(194, 75, 19, 0.4) 0.5rem,
          rgba(194, 75, 19, 0.2) 0.75rem,
        );
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(1.75rem);
  }
}


.toaster

  div[class~="bp4-toast"]
    margin: 2px
    min-width: 200px !important
    position: fixed !important
    border-radius: 4px
    padding: 2px 3px 0px 3px

    button[aria-label="Close"]
      border-radius: 99px
      margin-right: 1px

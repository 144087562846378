

.pagecontent
  padding: 5px 40px 20px 40px
  width: 100%
  max-width: 100vw

  @media (max-width: 800px)
    padding-left: 30px
    padding-right: 30px

  @media (max-width: 500px)
    padding-left: 20px
    padding-right: 20px



.topbar
  background-color: #FFFFFF !important
  border-bottom: 1px solid #E0E0E0
  border-left: 1px solid #E0E0E0
  border-right: 1px solid #E0E0E0
  box-shadow: none !important
  height: 52px !important
  padding: 0px 10px 0px 0px !important

  :global
    .bp4-popover2-target
      margin-left: 10px

      button
        box-shadow: none

    .logo
      cursor: pointer
      width: 220px
      margin-top: 7px

      // @media (max-width: 600px)
      //   width: 160px
      // @media (max-width: 400px)
      //   width: 140px

    .bp4-menu
      background: transparent

    .estAvatar
      margin-right: 3px
      margin-bottom: 2px

    .userAvatar
      margin-bottom: 4px

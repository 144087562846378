

.card
  margin-top: -20vh

  .container
    display: flex
    flex-direction: row

    .laading
      margin: 20px 30px 10px 30px
      min-width: 250px

    .form
      margin: 20px 30px 10px 30px
      min-width: 290px

    .banner
      border-radius: 0px 8px 8px 0px
      width: 280px
      background-color: #3a0078
      align-self: stretch
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      padding: 10px 20px

      img
        margin-top: 10px
        width: 160px

      p
        margin-top: 15px
        color: white
        text-align: center

  @media (max-width: 800px)
    .container
      flex-direction: column-reverse

      .banner
        border-radius: 8px 8px 0px 0px
        margin: -20px -22px 22px -22px
        width: 320px


@import "@blueprintjs/core/lib/scss/variables"

.textareafield

  textarea
    border-radius: 4px

  textarea::placeholder
    color: #bbb

  :global
    .error
      color: red
      font-family: $pt-font-family

    .bp4-input[readonly]
      box-shadow: none
      color: #666

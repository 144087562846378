
.appsidebar[class~="bp4-menu"]
  width: 100%
  position: sticky
  top: 0
  align-self: flex-start

  &>li
    margin: 3px 1px
    color: #757575

  &>li[class~="bp4-menu-header"]
    h6
      font-weight: 600

  &>li svg
    color: #757575

  &>li>a[class~="goods"]>span[class="bp4-menu-item-icon"]
    padding: 0 3px 1px 0px

  &>li>a[class~="bp4-selected"]
    background-color: #ECF2FD
    color: #1436BA

    svg
      color: #1436BA

  &>li>a[class~="bp4-menu-item"]
    border-radius: 4px
    padding: 8px 10px 8px 13px
    margin: 0px

  .discoverBox
    display: flex
    flex-direction: column
    align-items: center
    padding: 16px
    gap: 14px
    width: 100%
    background: #F5F5F5
    border-radius: 6px
    flex: none
    order: 0
    align-self: stretch
    flex-grow: 0
    margin-top: 100px

    img
      width: 100%
      margin: 10px

    h1
      margin-top: 0
      font-style: normal
      font-weight: 600
      font-size: 18px
      line-height: 30px
      text-align: center
      color: #1A1A1A

    p
      font-style: normal
      font-weight: 400
      font-size: 12px
      line-height: 20px
      text-align: center
      letter-spacing: 0.04em
      color: #616161

    a
      box-sizing: border-box
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      padding: 6px 16px
      gap: 8px
      width: 100%
      height: 38px
      background: #FFFFFF
      border: 1px solid #0037C1
      border-radius: 4px

  :global
    .bp4-icon.bp4-icon-translate
      padding: 0px 1px 0px 5px

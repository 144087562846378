/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
@import "@blueprintjs/core/lib/scss/variables";


.formField {
  div[class~="bp4-form-content"] {
    border-radius: 4px;
    border: 1px solid #cfcfd0;
    padding: 0px;
  }

  &.borderless {
    >div[class~="bp4-form-content"] {
      border: none;
    }
  }

  span[class~="bp4-icon-chevron-up"],
  span[class~="bp4-icon-chevron-down"] {
    svg {
      height: 14px;
      width: 14px;
    }
  }
}

:global .error {
  color: $pt-intent-danger
}
.widgetCard {
  width: 100%;
  position: relative;
  height: 75px;
  display: flex;
  flex-direction: row;

  div[class~="flex-column"] {
    width: 100%;
    height: 100%;
    padding: 8px 8px 10px 80px;

    h4 {
      margin: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 5px;
    }

    p {
      font-size: 12px;
      color: #9b9b9b;
      overflow: scroll;
      line-height: 1.2
    }
  }
}

.widgetIcon {
  position: absolute;
  left: 0;
  height: 100%;
  width: 68px;
  border-right: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widgetIcon svg {
  width: 35px;
  height: 35px;
}

.backout, .bp4-non-ideal-state
  margin: 20px 0px
  justify-content: flex-start

  div[class~="bp4-non-ideal-state-text"] > div
  font-size: 15px
  font-weight: 400
  font-family: "Lato"
  line-height: 20px
  // margin-top: 25px

  &.tall
    margin: 150px 0px

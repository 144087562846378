/* 
 * Copyright (C) Patient10x (https://www.patient10x.com) - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

.card {
  div[class~="flex-row"] {
    img {
      border-radius: 0px 8px 8px 0px;
    }

    h4 {
      margin-top: 10px;
      display: flex;
      align-items: center;

      svg {
        margin: -2.5px 5px 0px 0px;
      }
    }

    div[class~="flex-column"] {
      b {
        margin: 0px 0 10px 0;
        font-weight: 600;
        color: rgb(44, 56, 70);
        font-size: 13px;
      }
    }
  }
}
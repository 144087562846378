

.dropdownField
  select
    width: 100%
    -webkit-appearance: none
    -moz-appearance: none
    appearance: none
    display: inline-block
    padding: 12px 12px
    outline: none
    border: 0px solid
    border-radius: 4px
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)
    background-position: calc(100% - 15px) calc(1em + 3px), calc(100% - 10px) calc(1em + 3px), calc(100% - 2.5em) 0.5em
    background-size: 5px 5px, 5px 5px, 1px 1.5em
    background-repeat: no-repeat
    background-color: #fefeff !important
    color: #2e4a5c

.readOnly
  select
    opacity: 1
    color: #666
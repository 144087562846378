@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,800";
@import "https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,800";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,500";
@import "@blueprintjs/core/lib/scss/variables";


:root {
  // z-index
  --page-header-z-index: 5;
  --menu-z-index: 10;
  --guard-z-index: 15;
  --dialog-z-index: 20;
  --toast-z-index: 30;
}

body {
  margin: 0;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato';
  background: #F5F5F5;
  color: #1A1A1A;
  font-weight: 400;
  font-size: 14px;
}

body:after {
  content: '';
  position: fixed;
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
  z-index: -1;
  background: #F5F5F5;
}

input,
select {
  font-size: 15px;
  line-height: 17px;
}

#root {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #F5F5F5;
}

// Common
.fullscreenGuard {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: var(--guard-z-index);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #90a2a25d;
  backdrop-filter: blur(1.5px);
}

// Blueprint
.bp4-input {
  color: #444;
  transition: box-shadow 0ms;
  box-shadow: none;
}

.bp4-input[readonly] {
  // box-shadow: $pt-input-box-shadow
  box-shadow: none;
}

// dialog
.bp4-dialog.bp4-alert {
  margin: 10px;
}

// non-ideal-state
.bp4-non-ideal-state {
  height: unset;
}

// tabs
.bp4-tab-list {
  overflow-x: scroll;
  padding: 4px;
}

.bp4-tab-list::-webkit-scrollbar {
  display: none;
}

.bp4-tab {
  font-family: "Lato";
}

// card
.bp4-card.bp4-interactive {
  &:hover {
    box-shadow: $pt-elevation-shadow-2;
  }

  &:active {
    opacity: 0.9;
    box-shadow: $pt-elevation-shadow-0;
    transition-duration: 0;
  }
}

// slider
.bp4-slider-handle>.bp4-slider-label {
  background-color: #858687;
  font-size: 10pt;
}

.bp4-slider-axis>.bp4-slider-label {
  color: #999595;
}

// switch
.bp4-control.bp4-switch .bp4-control-indicator {
  height: 20px;
  min-width: 34px;

  &::before {
    height: 16px;
    width: 16px;
  }
}

.bp4-control.bp4-switch input:checked~.bp4-control-indicator::before {
  left: calc(100% - 20px);
}

// Popover
.bp4-popover2 {
  border-radius: 4px;

  .bp4-popover2-content {
    border-radius: 4px;

    .bp4-input-group {
      padding: 10px 8px;

      span.bp4-icon {
        left: 10px;
        top: 10px;
      }

      span.bp4-input-action {
        right: 8px;
        top: 10px;
      }

      input {
        border-radius: 4px;
        border: 1px solid #cfcfd0;
      }
    }
  }

  .bp4-menu {
    border-radius: 4px;
  }

  // ReCharts
}

.recharts-cartesian-axis-tick {
  font-size: 10px;
}

.recharts-legend-item-text {
  font-size: 12px;
}

@import "@blueprintjs/core/lib/scss/variables"

.sliderField
  :global
    .error
      color: red
      font-family: $pt-font-family

    div.bp4-form-content
      width: 100%
      padding: 5px 12px 0 11px

      div.bp4-slider
        width: 100%

        div.bp4-slider-track, div.bp4-slider-progress
          height: 14px
          top: 2px
          border-radius: 10px

        div.bp4-slider-progress.bp4-intent-primary
          background: #1436BA

        span.bp4-slider-handle
          border-radius: 99px
          background-color: white
          border: none
          box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)
          width: 17px
          height: 17px

          span.bp4-slider-label
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            padding: 6px 20px
            gap: 10px
            isolation: isolate
            width: 30px
            background: #0037C1
            border-radius: 6px

.muted
  :global
    div.bp4-form-content
      div.bp4-slider
        pointer-events: none
        cursor: default

        div.bp4-slider-track
          div
            cursor: default

          span
            cursor: default
            box-shadow: 0 0 0 0.1px

          div.bp4-slider-axis
            div
              cursor: default

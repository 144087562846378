

.card
  border-radius: 4px !important
  background-color: #ffffff !important
  // padding: 18px 18px 10px 18px !important
  padding: 0px !important

.card footer 
  font-family: 'Lato'
  font-size: 12pt
  color: #6e6e6e
  margin-top: auto

.animating
  animation: waves 30000s linear infinite forwards
  -webkit-backface-visibility: hidden
  backface-visibility: hidden
  cursor: none
  pointer-events: none

@keyframes waves
  0%
    background-position: 0vw 0
  100%
    background-position: 1000000vw 0

.colorPickerField {
  &[class~="bp4-form-group"] {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    label[class~="bp4-label"] {
      margin-bottom: 0px;
    }

    div[class~="bp4-form-content"] {
      min-height: unset;
      line-height: 1px;

      button {
        min-height: unset;
        min-width: unset;
        box-sizing: border-box;
        margin: 0 auto;
        width: 36px;
        height: 24px;
        border: 1px solid #E0E0E0;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 6px;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }
  }
}

@import "@blueprintjs/core/lib/scss/variables"

.form
  min-width: 250px
  max-width: 660px

  & > h3
    margin-top: -5px
    text-align: center
  & > button:first-of-type
    margin-top: 10px

  & .form
    border: 1px dashed #cacaca
    padding: 10px 15px 0px 20px
    border-radius: 4px
    margin-left: 2px
    margin-bottom: 10px

  :global
    .error
      color: $pt-intent-danger

  @media (max-width: 700px)
    min-width: 250px

  @media (max-width: 500px)
    min-width: 200px


.component

.googleButton
  min-width: 200px !important
  svg
    padding-top: -1px

.appleButton
  min-width: 200px !important
  svg
    margin-top: -4px
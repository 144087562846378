

.container
  border: none
  border-radius: 99px
  margin-top: 5px
  cursor: pointer
  display: grid
  background-color: #EDEDED

  span
    color: #6f6f6f
    font-weight: 400
    grid-column: 1
    grid-row: 1
    text-align: center
    margin-top: auto
    margin-bottom: auto

  img
    border-radius: 99px
    grid-column: 1
    grid-row: 1
    margin: -0.5px 0px 0px -0.5px
